$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: local('IBM Plex Mono SemiBold Italic'),
    local('IBMPlexMono-SemiBoldItalic'),
    url('#{$font-prefix}/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-SemiBoldItalic.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';