.background-about {
  background: 
    $background-gradient-blue,
    url("/assets/images/backgrounds/about-code-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background: 
      $background-gradient-blue,
      url("/assets/images/backgrounds/about-code-sm.jpg");
    background-size: cover;
    background-position: center center;
  }
}
  
.background-avodaq {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/avodaq.jpg");
  background-size: cover;
  background-position: center center;
}
  
.background-chat-collaboration {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/chat-collaboration-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/chat-collaboration-sm.jpg");
  background-size: cover;
  background-position: center center;
  }
}
  
.background-cloud {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/cloud-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background:
      $background-gradient-blue,
      url("/assets/images/backgrounds/cloud-sm.jpg");
    background-size: cover;
    background-position: center center;
  }
}
  
.background-dx {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/dx-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background:
      $background-gradient-blue,
      url("/assets/images/backgrounds/dx-sm.jpg");
    background-size: cover;
    background-position: center center;
  }
}
  
.background-voice-control {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/voice-control-xl.jpg");
  background-size: cover;
  background-position: bottom center;
  &.small {
    background:
      $background-gradient-blue,
      url("/assets/images/backgrounds/voice-control-sm.jpg");
    background-size: cover;
    background-position: bottom center;
  }
}
  
.background-process-technology {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/process-technology-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background:
      $background-gradient-blue,
      url("/assets/images/backgrounds/process-technology-sm.jpg");
    background-size: cover;
    background-position: center center;
  }
}
  
.background-saas {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/saas-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/saas-sm.jpg");
  background-size: cover;
  background-position: bottom center;
  }
}
  
.background-apm {
  background: 
    $background-gradient-blue,
    url("/assets/images/backgrounds/apm-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background: 
      $background-gradient-blue,
      url("/assets/images/backgrounds/apm-sm.jpg");
    background-size: cover;
    background-position: center center;
  }
}

.background-wave {
  background:
  $background-gradient-blue,
    url("/assets/images/backgrounds/complexity-wave-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background:
      $background-gradient-blue,
      url("/assets/images/backgrounds/complexity-wave-sm.jpg");
    background-size: cover;
    background-position: center center;
  }
}

.background-kubernetes {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/kubernetes-ship-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background:
      $background-gradient-blue,
      url("/assets/images/backgrounds/kubernetes-ship-sm.jpg");
    background-size: cover;
    background-position: center center;
  }
}

.background-ipv6 {
  background:
    $background-gradient-blue,
    url("/assets/images/backgrounds/ipv6-xl.jpg");
  background-size: cover;
  background-position: center center;
  &.small {
    background:
      $background-gradient-blue,
      url("/assets/images/backgrounds/ipv6-sm.jpg");
    background-size: cover;
    background-position: center center;
  }
}


.background-ciscochampion {
  background:
  $background-gradient-black,
    url("/assets/images/backgrounds/cisco-champions-2020.jpg");
  background-size: cover;
  background-position: right center;
}
 
/* Cisco Widget Darker Overlay on Smaller Screens */ 
@media only screen and (max-width : 720px) {
  .background-ciscochampion {
    background:
      $background-gradient-black-dark,
      url("/assets/images/backgrounds/cisco-champions-2020.jpg");
    background-size: cover;
    background-position: right center;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 360px) {
  .background-ciscochampion {
    background:
      $background-gradient-black-dark2,
      url("/assets/images/backgrounds/cisco-champions-2020.jpg");
    background-size: cover;
    background-position: right center;
  }
}