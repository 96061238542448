
$font-prefix: '../fonts' !default;

$font-prefix: '..' !default;
@font-face {
  font-family: 'Suisse Intl';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Bold-WebM.eot'); /* IE9 Compat Modes */
  src: local("Suisse Intl"),
    local("Suisse Intl Fett"),
    url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Bold-WebM.eot?iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Bold-WebM.woff') format('woff'), /* Modern Browsers */
    url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Bold-WebM.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Bold-WebM.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Bold-WebM.svg') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Suisse Intl';
    font-style: normal;
    font-weight: 600;
    src: local("Suisse Intl"),
      local("Suisse Intl Halbfett"),
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-SemiBold-WebM.eot?iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-SemiBold-WebM.woff') format('woff'), /* Modern Browsers */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-SemiBold-WebM.woff2') format('woff2'), /* Super Modern Browsers */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-SemiBold-WebM.ttf') format('truetype'), /* Safari, Android, iOS */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-SemiBold-WebM.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Suisse Intl';
    font-style: normal;
    font-weight: 500;
    src: local("Suisse Int'l"),
      local("Suisse Int'l Regular"),
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Medium-WebM.eot?iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Medium-WebM.woff') format('woff'), /* Modern Browsers */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Medium-WebM.woff2') format('woff2'), /* Super Modern Browsers */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Medium-WebM.ttf') format('truetype'), /* Safari, Android, iOS */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Medium-WebM.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Suisse Intl';
    font-style: normal;
    font-weight: 400;
    src: local("Suisse Int'l"),
      local("Suisse Int'l Regular"),
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Regular-WebM.eot?iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Regular-WebM.woff') format('woff'), /* Modern Browsers */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Regular-WebM.woff2') format('woff2'), /* Super Modern Browsers */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Regular-WebM.ttf') format('truetype'), /* Safari, Android, iOS */
      url('#{$font-prefix}/Suisse-Intl/SuisseIntl-Regular-WebM.svg') format('svg'); /* Legacy iOS */
  }