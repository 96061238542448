$font-sans: "IBM Plex Sans", sans-serif;
$font-sans-heading: "Suisse Intl", sans-serif;
$font-mono: "IBM Plex Mono", monospace;

$font-color: #111;
$font-color-heading: #222;

$highlight-color: rgb(0, 163, 255); //#47D4F8
$highlight-color2: #47D4F8;

/* $background-gradient-blue: linear-gradient(135deg, rgba(58, 130, 226, 0.58), rgba(33, 46, 147, 0.78)); OLD VERSION*/

$background-gradient-blue: linear-gradient(135deg, rgba(34, 115, 221, 0.58), rgba(33, 46, 147, 0.6));
$background-gradient-blue-logo: linear-gradient(135deg, rgba(0, 163, 255, 1), rgba(33, 46, 147, 1));
$background-gradient-black: linear-gradient(135deg, rgba(0, 0, 0, 0.58), rgba(10, 10, 10, 0.25));
$background-gradient-black-dark: linear-gradient(135deg, rgba(0, 0, 0, 0.75), rgba(10, 10, 10, 0.75));
$background-gradient-black-dark2: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(10, 10, 10, 0.75));

$background-gradient-dark: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(10, 10, 10, 0.5) 100%);
$background-gradient-dark2: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(10, 10, 10, 0.75) 100%);

$text-shadow: 0.2vh 0.2vh 0.5vh rgba(0,0,0,0.5);