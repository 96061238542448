// Imports
@import "type";
@import "variables";
@import "backgrounds";
@import "footer";


html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
}

body {
  color: $font-color;
  font-family: $font-sans;
  font-weight: 400;
} 

@media only screen and (max-width : 450px) {
  body {
   /* font-size: 15px; */
  }
}

h1, h2, h3, h4, h5  {
  color: $font-color-heading;
  font-family: $font-sans-heading;
  margin: 0 0 0 0;
}

h1 {
  font-size: 2.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

h3 {
  font-size: 2.1rem;
  font-weight: 600;
  line-height: 1.2;
}

@media only screen and (max-width : 450px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 1.6rem;
  font-weight: 400;
}

h5 {
  font-size: 1.2rem;
  font-weight: 500;
}

em {
  font-family: $font-mono;
}

:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  color: $highlight-color;
  font-family: $font-mono;
}

.sb-container {
  padding-left: 0;
  padding-right: 0;
}

.sb-grid-small > * {
  padding-left: 6px;
}

*+.sb-grid-margin-small, .sb-grid+.sb-grid-small, .sb-grid-small>.sb-grid-margin {
  margin-top: 6px;
}

.sb-grid-small {
  &.grid-ciscochampion {
    margin-bottom: 6px;
    margin-top: 6px;
    .background-ciscochampion {
      min-height: 320px;
      height: 100%;
      .post-meta-wrapper {
        margin-top: 40px
      }
      a {
        text-align: left;
      }
      p {
        max-width: 65%;
      }
    }
  }
}

@media only screen and (max-width : 720px) {
  .sb-grid-small {
    &.grid-ciscochampion {
      .background-ciscochampion {
        p {
          max-width: 100%;
        }
      }
    }
  }
}


a {
  color: #333;
  &:hover,
  &:active {
    color: #444;
    text-decoration: none;
  }
}
.external-link-icon {
  position: relative;
  left:2px;
  top:-2px;
}


.grid-item {
  .grid-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.2));
    padding: 25px 25px;
    text-shadow: $text-shadow;
    &.darker {
      background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.35) 50%, rgba(0,0,0,0.45));
    }
  }
  .background-wrapper-1 {
    display: flex;
    justify-content: flex-end;
    min-height: 420px;
    height: 100%;
  }
  .background-wrapper-2 {
    display: flex;
    justify-content: flex-end;
    min-height: 320px;
    height: 100%;
  }
  .grid-title, .post-meta, p, a {
    color: #fff;
  }
  .grid-title {
    font-family: $font-sans-heading;
    font-weight: 600;
    line-height: 120%;
    font-size: 36px;
    color: rgba(255, 255, 255, 1);
    transition: color 120ms ease-in-out;
    &:hover,
    &:active {
      color: rgba(255, 255, 255, 0.8);
    }
    &.small {
      font-size: 28px;
    }
  }
  p {
    color: #fff;
    font-size: 1.05rem;
    margin: 10px 0 0 0;
    &.small {
      font-size: 0.97rem;
    }
    a {
      transition: color 120ms ease-in-out;
    }
    a:hover,
    a:active {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  .post-meta-wrapper {
    margin: 20px 0 10px;
    display: block;
  }
  .post-meta {
    font-family: $font-mono;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    width: auto;
    margin-right: 10px;
  }
  .post-meta-2 {
    padding: 3px 8px 3px 3px;
    height: 23px;
    color: #fff;
    font-family: $font-mono;
    text-transform: uppercase;
    font-size: 14px;
  }
  a.more {
    margin-top: 7px;
    display: block;
    color: #fff;
    font-size: 16px;
    font-family: $font-mono;
    text-decoration: underline;
    text-transform: uppercase;
    text-underline-position:under;
    -webkit-text-underline-position: under;
    &:hover,
    &:active {
      text-decoration-color: rgba(255, 255, 255, 0.5);
      -webkit-text-decoration-color: rgba(255, 255, 255, 0.5);
    }
  }
  em {
    color: #fff;
  }
}
@media only screen and (max-width : 960px) {
  .grid-item {
    .grid-title {
      &.small {
        font-size: 36px;
      }
    }
    p {
      margin-top: 10px;
      &.small {
        font-size: 1.05rem;
      }
    }
    .background-wrapper-2 {
      min-height: 420px;
      height: 100%;
    }
  }
}


@media only screen and (max-width : 400px) {
  .grid-item {
    .grid-title {
      font-size: 28px;
      &.small {
        font-size: 28px;
      }
    }
  }
}


nav {
  background: rgba(10,10,10,0.97);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 10px 0;
  line-height: 100%;
  height: 35px;
  ul {
    flex: 1;
    align-self: flex-end;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-family: $font-sans;
    li {
      display: inline;
      padding: 0 10px;
      &:last-child {
        padding-right: 5px;
      }
    }

  }
  .sb-logo {
    font-size: 17px;
    padding-left: 15px;
    &:hover,
    &:active {
      transition: all 180ms ease-in-out;
      /*background-color: $highlight-color;*/
      background-image: $background-gradient-blue-logo;
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-text-fill-color: transparent;
    }
  }
  .nav-item {
    color: #aeaeae;
    
    a {
      color: #fcfcfc;
      transition: all 150ms ease-in;
      position: relative;
      padding: 3px 0;
      border-bottom: 2px solid transparent;
      text-shadow: $text-shadow;
      &.nav-item-link {
        top: 5px;
        &:hover {
          color: #fff;
          border-bottom: 2px solid #fff;
        }
        &:active,
        &.active {
          color: $highlight-color;
        }
      }

    }
    &.lang {
      padding: 0 5px;
      &:last-child {
        padding-right: 15px;
      }
    }
    .lang-link {
      display: inline-flex;
      padding: 2px;
      margin-top: 0;
      border-radius: 50%;
      border: 2px solid transparent;
      .flag {   
        display: inline-block;
        &-english {
          background: url("/assets/images/en.svg") no-repeat;
          background-position: center center;
          background-size: 25px 25px;
          width: 25px;
          height: 25px;
          color: transparent;
          text-shadow: none;
          &.not-available {
            filter: grayscale(100%);
          }
        }
        &-german {
          background: url("/assets/images/de.svg") no-repeat;
          background-position: center center;
          background-size: 25px 25px;
          width: 25px;
          height: 25px;
          color: transparent;
          text-shadow: none;
          &.not-available-de {
            filter: grayscale(100%) brightness(110%);
          }
        }
      }
    }
  }
  .nav-item .lang-link:hover,
  .activelang {
    background: #333;
    border: 2px solid #fff;
  }
  .nav-item .lang-link:active {
    border: 2px solid $highlight-color;
  }
  .search-text {
    padding-right: 2px;
  }
  .nav-search-icon {
    top: -2px;
    position: relative;
  }
}

#mobileNavigation {
  .nav-item {
    a.active {
      color: $highlight-color;
    }
  }
  .sb-offcanvas-close {
    margin-right: 7px;
  }
  .search-text {
    padding-right: 2px;
  }
  .nav-search-icon {
    top: -2px;
    position: relative;
  }
}

@media only screen and (max-width : 300px) {
  nav {
    .nav-item.search{
      display: none;
    }
  }
}

@media only screen and (max-width : 375px) {
  nav {
    .navigation-toggle {
      padding: 0 30px 0 0;
    }
  }
}

@media only screen and (max-width : 800px) {
  nav {
    .search-text {
      display: none;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 640px) {
 nav {
  position: fixed;
 }
}

.sb-offcanvas-bar {
  margin-top: 52px;
  background: rgba(17,17,17,0.8);
  a {
    color: #fff;
    transition: all 180ms ease-in-out;
    &:hover,
    &:active {
      color: #ccc;
    }
  }
}
.sb-logo {
  font-family: $font-sans-heading;
  font-weight: 600;
  color: #fefefe;
  text-transform: uppercase;
}

@media only screen and (min-width : 640px) {
  .sb-logo {
    text-transform: none;
  }
}



#language-fallback {
  .sb-modal-title {
    color: #000;
    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: 0
  }
  ul.no-list {
    list-style-type: none;
    text-align: center;
    padding-left: 0;
    li {
      display: inline;
      margin: 5px;
      padding: 0;
    }
    .sb-button-default {
      border: 1px solid silver;
      margin: 5px 0;
      &:hover,
      &:active {
        border: 1px solid #333;
      }

    }
  }
}

@media only screen and (max-width : 350px) {
  #language-fallback {
    ul.no-list {
      li {
        margin: 0;
      }
      .sb-button-default {
        display: block;
      }
    }
  }
}

/* Font Styles for Content Area */
.content {
  padding: 20px;
  p {
    margin: 0.5rem 0 1rem 0;
    line-height: 165%;
    font-size: 1.1rem;
  }
  ul {
    font-size: 1.1rem;
  }
  h2, h3, h4, h5 {
    margin-top: 1.8rem;
  }
  .info-box {
    font-size: 0.95rem;
    margin: 30px 0 40px;
    line-height: 155%;
  }
  .date {
    display: block;
    font-family: $font-mono;
    color: #AFAFAF;
    font-weight: 350;
    text-transform: uppercase;
    margin: 30px 0 20px 0;
  }
  a.date {
    text-decoration: none;
    margin: 10px 0;
  }
  .post-title {
    font-weight: bold;
    font-size: 1.1rem;
  }
  .list {
    list-style-type: square;
    line-height: 180%;
    margin-bottom: 20px;
  }
  .footnotes {
    border-top: 1px solid #e5e5e5;
    margin: 20px 0 15px 0;
    padding: 20px 0 0 20px;
    font-size: 13px;
    counter-reset: item;
    list-style: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    li {
      padding: 0 0 10px 0;
      position: relative;
      display: block;
    }
    li:before{
    	content: counter(item);
      counter-increment: item;
      position: absolute;
      font-size: 11px;	
      left: -15px;
    	vertical-align: super;
    }
  }
  em {
    font-family: $font-mono;
    font-weight: normal;
    color: #000;
    word-spacing: -4px;
  }
  a {
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    word-break: break-all;
    -ms-word-break: break-all;
    color: #111;
    text-decoration-color: $highlight-color;
    -webkit-text-decoration-color:  $highlight-color;
    text-underline-position:under;
    -webkit-text-underline-position: under;
    &:hover,
    &:active {
      text-decoration-color: #111;
      -webkit-text-decoration-color:  #111;
    }
  }
  blockquote,
  .forward-momentum {
    font-family: $font-sans-heading;
    font-style: italic;
    font-size: 30px;
    line-height: 1.3;
    color: #111;
    text-align: center;
    margin-bottom: 30px;
  }
  .talks-year {
    font-size: 1.1rem;
    margin-top: 0.5rem;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  } 
  ul.talks-list {
    list-style: none;
    margin: 0;
    padding: 0 0 10px 0;
    line-height: 165%;
  }
  ul.dashed {
    list-style-type: none;
    margin: 0;
    padding: 0 0 5px 0;
    display: table;
    & > li {
      display: table-row;
      &:before {
        content: "—";
        padding: 2px 10px 2px 5px;
        display: table-cell;
        text-align: right;
      }
    }
  }
}

@media only screen and (max-width : 639.5px) {
  .content {
    p,
    ul,
    .post-title,
    .talks-year {
      font-size: 1.05rem;
    }
  }
}



.info-box {
  padding: 15px 25px 15px 25px;
  color: #222;
  background: #f1f1f1;
  font-size: 0.95rem;
  border: 1px solid rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.25);
  box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.25);
  position: relative;
  p {
    color:  #222;
    font-size: 0.95rem;
  }
  a {
    line-height: 165%;
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    word-break: break-all;
    -ms-word-break: break-all;
    color: #111;
    text-decoration-color: $highlight-color;
    -webkit-text-decoration-color:  $highlight-color;
    text-underline-position:under;
    -webkit-text-underline-position: under;
    &:hover,
    &:active {
      color: #333!important;
      text-decoration-color: #111;
      -webkit-text-decoration-color:  #111;
    }
  }
  em {
    color: #222;
  }
  .button {
    background: $highlight-color;
    padding: 2px 4px;
    border-radius: 3px;
    margin-left: 5px;
  }
  .title {
    display: block;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #111;
  }

  &:before {
    content:"";
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    height: 3px;
    width: 200px;
    background: #111;
  }
  &:after {
    content:"";
    display: block;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 3px;
    width: 200px;
    background: #111;
  }
}


.about-image {
  padding: 25px;
  margin: 0 auto;
  max-width: 425px;
  .image-cropper {
    position: relative;
    overflow: hidden;
    height: 0;
    margin: 0.5em;
    flex-basis: calc(100% - 1em);
    padding-bottom: calc(100% - 1em);
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    box-shadow: 0.2vh 0.2vh 0.5vh rgba(0, 0, 0, 0.5);
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width : 1024px) {
  .about-image {
    max-width: 350px;
    .image-cropper {
      
    }
  }
}


.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld); 
}

.start {
  .first-row {
    margin-top: 58px;
    margin-bottom: 6px;
    p {
      max-width: 65%;
    }
  }
  .last-updates {
    margin-bottom: 6px;
  }
  .container-1920 {
    max-width: 1920px;
  }
  .content-width-1160 {
    max-width: 1160px;
    margin: 0 auto;
  }
  .content-width-555 {
    max-width: 555px;
    &.flex-end {
      align-self: flex-end;
    }
  }
  .show-posts {
    margin-top: 15px;
    margin-bottom: 20px;
  }
  a.show-posts-link {
    padding: 20px 25px;
    text-align: right;
    color: #111;
    font-size: 14px;
    font-family: $font-mono;
    text-decoration: underline;
    text-transform: uppercase;
    text-decoration-color: $highlight-color;
    -webkit-text-decoration-color: $highlight-color;
    text-underline-position:under;
    -webkit-text-underline-position: under;
    &:hover,
    &:active {
      text-decoration-color: #111;
      -webkit-text-decoration-color:  #111;
    }
  }
}

@media only screen and (max-width : 1075px) {
  .start {
    .background-about {
      .content-width-555 {
        padding-top: 50px;
      }
    }
  }
}

@media only screen and (max-width : 720px) {
  .start {
    .first-row {
      p {
        max-width: 100%;
      }
    }
    .content-width-555 {
      &.flex-end {
        align-self: unset;
      }
    }
  }
}

@media only screen and (max-width : 320px) {
  .grid-item {
    a.more, p, p.small {
      font-size: 15px;
    }
  }
}

.post-header {
  min-height: 80vh;
  height: 100%;
  margin-bottom: 10px;
  .background-overlay {
    background: $background-gradient-dark;
    display: block;
    height: 100%;
    min-height: 80vh;
    display: flex;
    align-items: flex-end;
  }
  .sb-container {
    padding-top: 150px;
    padding-bottom: 70px;
    max-width: 1000px;
  }
  h1 {
    padding: 0 20px;
    color: #fff;
    line-height: 110%;
    font-size: 4.5rem;
    text-transform: none;
    text-shadow: $text-shadow;
    margin-bottom: 20px;
  }
  .subtitle {
    margin: 0;
    padding: 0 20px;
    color: #fff;
    line-height: 150%;
    font-size: 1.5rem;
    text-shadow: $text-shadow;
    em {
      color: $highlight-color2;
    }
  }
}

@media only screen and (max-width : 1024px) {
  .post-header {
    .sb-container {
      padding-bottom: 50px;
    }
    h1 {
      font-size: 3.2rem;
      margin-bottom: 15px;
    }
    .subtitle {
      font-size: 1.4rem;
    }
  }
}

@media only screen and (max-width : 580px) {
  .post-header {
    min-height: 70vh;
    .background-overlay {
      min-height: 70vh;
    }
    .sb-container {
      padding-bottom: 30px;
    }
    h1 {
      font-size: 2.8rem;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
    .subtitle {
      font-size: 1.3rem;
    }
  }
}

@media only screen and (max-width : 375px) {
  .post-header {
    .sb-container {
      padding-bottom: 20px;
    }
    h1 {
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
    .subtitle {
      font-size: 1.1rem;
    }
  }
}

@media only screen and (max-width : 320px) {
  .post-header {
    min-height: none;
    .background-overlay {
      min-height: none;
    }
    .sb-container {
      padding-top: 100px;
    }
  }
}

.about {
  .post-header {
    .background-overlay {
      background: $background-gradient-dark2;
    }
    .sb-container {
      padding-top: 75px;
    }
  }
}

.content {
  .post-meta-wrapper {
    display: inline-block;
    padding-bottom: 10px;
  }
  .post-meta,
  .post-meta-2 {
    font-family: $font-mono;
    text-transform: uppercase;
    color: $font-color-heading;
    font-size: 0.9rem;
    &.update {
      margin-left: 10px;
      padding: 2px 3px;
      border:1px solid rgba(0, 0, 0, 0.5);
      border-radius: 2px;
    }
  }
  .post-meta-2 {
    margin-left: 10px;
    padding: 3px 5px;
  }
  .subtitle {
    font-size: 1.2rem;
    font-weight: 500;
  }
}

@media only screen and (max-width : 525px) {
  .content { 
    .post-meta,
    .post-meta-2 {
      margin-bottom: 5px;
      &.update {
        margin-top: 10px;
        margin-left: 0;
        display: block;
      }
    }
  }
}

.default,
.posts {
  .content {
    margin-top: 80px;
  }
}

@media only screen and (max-width : 720px) {
  .posts {
    .posts-meta {
      .post-meta-2 {
        .lang-text {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width : 480px) {
  .posts {
    .posts-meta {
      display: block;
      .post-meta-2 {
        margin-left: 0;
        padding-left: 0;
        padding-top: 10px;
        display: block;
        .lang-text {
          display: inline;
        }
      }
    }
  }
}

.more-content {
  background: rgba(0,0,0,0.1);
  text-align: center;
  padding: 30px;
  margin-top: 20px;
  font-family: $font-mono;
  text-transform: uppercase;
}

.more-posts {
  .grid-item {
    .grid-content-wrapper {
      width: 100%;
    } 
    .post-meta-wrapper {
      max-width: 525px;
      width: calc(100% - 50px);
      padding: 10px 25px;
      margin: 0 auto;
      .post-meta {
        margin: 0 0 10px 0;
      }
    }
    a.grid-title {
      max-width: 525px;
      width: calc(100% - 50px);
      padding: 0px 25px;
      margin: 0 auto;
    }
    p {
      max-width: 525px;
      font-size: 0.97rem;
      width: calc(100% - 50px);
      text-align: left;
      margin: 5px auto;
      padding: 5px 25px;
    }
    a.more {
      padding: 0;
      text-align: right;
    }
  }
  .grid-item:nth-child(1) {
    .post-meta-wrapper,
    .more-link-wrapper {
      margin-right: 0;
      .post-meta {
        margin: 0 0 10px 0;
      }
    }
    a.grid-title {
      margin-right: 0;
    }
    p {
      margin-right: 0;
    }
    a.more {
      margin-right: 0;
    }
  }
  .grid-item:nth-child(2) {
    .post-meta-wrapper,
    .more-link-wrapper {
      margin-left: 0;
      .post-meta {
        margin: 0 0 10px 0;
      }
    }
    a.grid-title {
      margin-left: 0;
    }
    p {
      margin-left: 0;
    }
    a.more {
      margin-left: 0;
    }
  }
  .grid-item:only-child {
    .post-meta-wrapper {
      align-self: center;
      max-width: 700px;
      margin: 0 auto;
      .post-meta {
        margin: 0;
      }
    }
    a.grid-title {
      max-width: 700px;
      margin: 0 auto;
    }
    p {
      max-width: 700px;
      margin: 0 auto;
    }
    .more-link-wrapper {
      align-self: center;
      max-width: 700px;
      margin: 0 auto;
    }
  }
}

.search {
  margin-top: 25px;
  padding-bottom: 50px;
  form {
    display: flex;
  }
  input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
  }
  #lunrsearchresults {
    padding-top: 0.2rem;
    p {
      padding-bottom: 15px;
      border-bottom: 1px solid #ccc;
    }
    ul {
      padding: 0;
    }
    .lunrsearchresult {
      list-style: none;
      padding-bottom: 1.5rem;
      .title {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .flag {
        margin-left: 5px;
      }
      .url a {
        color: silver;
        text-decoration: none;
      }
    }
  }
}



.navigation-toggle {
  background: none;
  .icon-bar {
      transition: 200ms ease-in-out;
      background-color: #fff;
      position: relative;
      display: block;
      width: 26px;
      height: 2px;
      margin: 5px;
  }
  &:hover {
    .icon-bar {
      background-color: #ccc;
    } 
  }
}

body.sb-offcanvas-container {
  .navigation-toggle {
      .icon-bar {
        &:first-child {
          opacity: 0;
        }
        &:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 0;
        }
        &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: -7px;
        }
      }
    }
  }


@media (min-width:820px){
  .sb-width-1-1\@sm{width:100%}
  .sb-width-1-2\@sm{width:50%}
  .sb-width-1-3\@sm{width:calc(100% * 1 / 3.001)}
  .sb-width-2-3\@sm{width:calc(100% * 2 / 3.001)}
}

.cc-button {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  overflow: visible;
  background: transparent;
  border: 0;
  padding: 3px 5px;
  border: 1px solid #898989;
  border-radius: 2px;
  color: #898989;
  cursor: pointer;
  &:hover {
    border: 1px solid #999;
    color: #999;
  }
}
.cc-title {
  color: #000;
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 10px;
  text-align: center;
}


.flag {
  display: inline-block;
  width: 18px;
  height: 18px;
  color: transparent;
  text-shadow: none;
  position: relative;
  box-shadow: 0vh 0vh 0.35vh rgba(0,0,0,0.5);
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 50%;
  top: 4px;
  &-en {
    background: url("/assets/images/en.svg") no-repeat;
    background-position: center center;
    background-size: 18px 18px;
  }
  &-de {
    background: url("/assets/images/de.svg") no-repeat;
    background-position: center center;
    background-size: 18px 18px;
  }
}

.content-width {
  max-width: 880px;
}

.content-width-sm {
  max-width: 650px;
}