$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 450;
  src: local('IBM Plex Serif Text'),
    local('IBMPlexSerif-Text'),
    url('#{$font-prefix}/IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Text.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';