blockquote.fm {
  font-family: $font-sans-heading;
  font-weight: 500;
  font-style: normal;
  font-size: 3.8rem;
  
  margin: 0 0 0 0;
  line-height: 120%;
  text-align: center;
  background: rgba(0,0,0,0.95);
  padding: 100px 0;
  .quote {
    padding: 0 25px;
    background-color: $highlight-color;
    background-image: $background-gradient-blue;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  .quoteadd {
    display: block;
    color: #fff;
    font-family: $font-mono;
    font-weight: 300;
    font-size: 1.6rem;
    padding: 0;
    margin-top: 10px;
    line-height: 100%;    
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 650px) {
  blockquote.fm {
    font-size: 3rem;
    .quoteadd {
      font-size: 1.4rem;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  blockquote.fm {
    padding: 50px 0;
  }
}

footer {
    padding: 40px 25px 50px;
    text-align: left;
    font-size: 15px;
    background: rgba(0,0,0,0.90);
    ul {
      list-style: none;
      padding: 0;
      margin: 10px;
      li {
        display: inline;
        &:not(:last-child):after  {
          padding: 0 5px 0 10px;
          content: "-";
        }
      }
    }
    .copyright {
      color: #fff;
      font-size: 13px;
      font-family: "IBM Plex Mono", monospace;
      text-transform: uppercase;
      margin-bottom: 20px;
      li {
        display: inline;
        &:not(:last-child):after  {
          padding: 0 5px 0 10px;
          content: " ";
        }
      }
    }
    .tech-details {
      font-family: "IBM Plex Mono", monospace;
      color: #898989;
      font-size: 12px;
    }
    a {
      color: #ccc;
      &:hover,
      &:active {
        color: #fff;
      }
    }
  }

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  footer {
    ul.copyright {
      text-align: center;
    }
    ul.tech-details {
      list-style-type: none;
      padding: 0;
      font-size: 12px;
      margin: 0;
      text-align: center;
      li {
        padding: 4px 0;
        display: block;
        &:after {
          content:'';
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width : 360px) {
  footer {
    font-size: 13px;
    padding: 30px 25px 40px;
    ul.tech-details {
      font-size: 10px;
      text-align: right;
    }
    ul.copyright {
      li {
        margin: 40px 0;
      }
    }
  }
}