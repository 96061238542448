$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: local('IBM Plex Sans ExtraLight'),
    local('IBMPlexSans-ExtraLight'),
    url('#{$font-prefix}/IBM-Plex-Sans/fonts/complete/woff/IBMPlexSans-ExtraLight.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';