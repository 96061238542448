$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Serif Medium'),
    local('IBMPlexSerif-Medium'),
    url('#{$font-prefix}/IBM-Plex-Serif/fonts/complete/woff/IBMPlexSerif-Medium.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';