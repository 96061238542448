$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Sans Condensed Bold'),
    local('IBMPlexSansCondensed-Bold'),
    url('#{$font-prefix}/IBM-Plex-Sans-Condensed/fonts/complete/woff/IBMPlexSansCondensed-Bold.woff') format('woff');
}

@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';